import React from "react";
import { Link } from "gatsby";

const Badge = ({ children, classes }) => {
  return (
    <div className="inline-flex flex-no-wrap items-center border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground hover:bg-gray-200/80 gap-2 bg-gray-200 px-3 py-1 rounded-full text-sm">
      <span className={`w-2 h-2 rounded-full inline-block ${classes}`}></span>
      {children}
    </div>
  );
};

export default Badge;
